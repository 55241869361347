import {
  OwnUpSmallBodyItalic,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled, { css } from 'styled-components';
import { YotpoTotalReviewsBadge } from '../../../images/yotpo-total-reviews-badge';
import { OverlineText } from '../../overline-text';

const commonPadding = css<PropsWithTheme<{}>>`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }) => theme.spacing(5)}px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ItalicText = styled(OwnUpSmallBodyItalic)`
  color: ${SLATE_100};
  ${commonPadding}
`;

const YotpoBadgeWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const YotpoReviewTitle = ({ bottomLine }: { bottomLine: number }) => {
  return (
    <Wrapper>
      <OverlineText>COMMUNITY REVIEWS</OverlineText>
      <ItalicText variant="body1">Powered by YOTPO</ItalicText>
      <YotpoBadgeWrapper>
        <YotpoTotalReviewsBadge totalReviews={bottomLine} />
      </YotpoBadgeWrapper>
    </Wrapper>
  );
};
