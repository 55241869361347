import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import styled from 'styled-components';
import { decodeHtml } from '../../../modules/formatting';
import { Pagination } from '../pagination';
import { Review as ReviewIndividual, ReviewProps } from '../review';
import { Section } from '../section';
import { YotpoReviewTitle } from './title';

interface Review {
  score: number;
  content: string;
  title: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  created_at: string;
  user: {
    display_name: string;
  };
  /* eslint-enable @typescript-eslint/naming-convention */
}

const PaddedGridItem = styled(OwnUpGridItem)`
  padding-top: ${({ theme }) => theme.spacing(6)}px !important;
  ${({ theme }) => theme.breakpoints.only('md')} {
    padding-top: ${({ theme }) => theme.spacing(8)}px !important;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(10)}px !important;
  }
`;

const Review = ({ data }: { data: ReviewProps }) => (
  <PaddedGridItem xs={12} md={4}>
    <ReviewIndividual {...data} />
  </PaddedGridItem>
);

const PaginationWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-top: ${({ theme }) => theme.spacing(7)}px;
  }
`;

export const ReviewGrid = ({
  bottomLine,
  currentPage,
  reviews,
  setCurrentPage
}: {
  bottomLine: number;
  currentPage: number;
  reviews: Review[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}) => {
  const reviewsRef = useRef<HTMLDivElement>(null);
  const scrollToRef = () => {
    if (reviewsRef && reviewsRef.current) {
      reviewsRef.current.focus();
      const bodyPos = document.body.getBoundingClientRect().top;
      const elPos = reviewsRef.current.getBoundingClientRect().bottom;
      const offset = elPos - 80 - bodyPos;
      window.scrollTo({ left: 0, top: offset, behavior: 'smooth' });
    }
  };
  const numberOfPages = Math.ceil(bottomLine / 6);

  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <Section aria-label="reviews grid">
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="slim">
            <OwnUpGridOffset xs={0} md={4} />
            <OwnUpGridItem xs={12} md={4}>
              <YotpoReviewTitle bottomLine={bottomLine} />
            </OwnUpGridItem>
            <OwnUpGridOffset xs={0} md={4} />
            <div ref={reviewsRef} />
            {reviews.map(
              (
                { score, content, title, created_at: createdAt, user: { display_name: name } },
                i
              ) => (
                <Review
                  key={i}
                  data={{
                    score,
                    content: decodeHtml(content),
                    title: decodeHtml(title),
                    createdAt,
                    name
                  }}
                />
              )
            )}
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
        <PaginationWrapper>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numOfPages={numberOfPages}
            scrollToTop={scrollToRef}
          />
        </PaginationWrapper>
      </Section>
    </OwnUpThemeProvider>
  );
};
